import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
// import { en } from "../locales/en";
// import { fa } from "../locales/fa";
// import { tr } from "../locales/tr";
// import { ru } from "../locales/ru";

let domain = window.location.hostname;
if (domain.indexOf('www.') > -1) {
    domain = domain.substr(4);
}
let protocol = window.location.protocol;
// let apiBaseUrl = protocol + '//api.' + domain + '/v1/';
let apiBaseUrl = protocol + '//' + domain + '/api/v1/';

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    .use(HttpApi)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: false,
        //preload: false,
        fallbackLng: 'en',
        load: 'languageOnly',
        backend: {
            loadPath: apiBaseUrl + 'default/translate?lang={{lng}}',
            queryStringParams: { v: '1.0' },
            crossDomain: false,
            //allowMultiLoading: false
        },
        react: {
            //wait: true
            useSuspense: false
        },
/*        resources: {
            en: {
                translation: en,
            },
            fa: {
                translation: fa,
            },
            tr: {
                translation: tr,
            },
            ru: {
                translation: ru,
            },
        }*/
    }, (err, t) => {
        if (err) return console.log("i18n: " + err)
    });

export default i18n;
