import React from "react";

class CountDownTimer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            time: {},
            seconds: props.seconds,
            finish: false
        };
        this.timer = 0;
    }

    secondsToTime = (secs) => {
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        hours = hours.toString().padStart(2, '0');
        minutes = minutes.toString().padStart(2, '0');
        seconds = seconds.toString().padStart(2, '0');

        return {
            "h": hours,
            "m": minutes,
            "s": seconds
        };
    }

    componentDidMount() {
        let timeLeftVar = this.secondsToTime(this.state.seconds);
        this.setState({ time: timeLeftVar }, () => {
            this.startTimer();
        });
    }

    startTimer = () => {
        if (this.timer === 0 && this.state.seconds > 0) {
            this.timer = setInterval(this.countDown, 1000);
        }
    }

    countDown = () => {
        // Remove one second, set state so a re-render happens.
        let seconds = this.state.seconds - 1;
        this.setState({
            time: this.secondsToTime(seconds),
            seconds: seconds,
        });

        // Check if we're at zero.
        if (seconds === 0) {
            clearInterval(this.timer);
            this.setState({finish: true})
            this.props.handleZeroCounter();
        }
    }

    render() {
        return(
            <span className={`${this.props.className ? this.props.className : `text ${!this.state.finish ? 'text-success' : ''}`}`}>{this.state.time.m}:{this.state.time.s}</span>
        );
    }
}



export default CountDownTimer;