const LocalStorage = {
    getItem(itemName) {
        return localStorage.getItem(itemName)
    },
    saveItem(itemName, itemValue) {
        try {
            localStorage.setItem(itemName, itemValue)
            return true;
        } catch (e) {
            return false;
        }
    },
    removeItem(itemName) {
        localStorage.removeItem(itemName)
    },
    removeAll() {
        localStorage.clear();
    },
};

export default LocalStorage