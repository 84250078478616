import React, {Suspense} from "react";
import {connect} from "react-redux";
import ResultCallBackForm from "./ResultCallBackForm";
import {withTranslation} from "react-i18next";
import Utils from "../../services/Utils";
import Water from "../../commons/water/Water";

class Result extends React.Component {
    render() {
        let ResultRouter = null
        if(this.props.result !== null) {
            const companyFolderName = Utils.capitalizeFirstChar(this.props.result.paymentMethod);
            const component = React.lazy(() => import('../' + companyFolderName + '/Result'));
            ResultRouter = React.createElement(component);
        }

        return (
            <div className="result-section mt-5">
                {
                    this.props.resultError ?
                        <div className="failed-result d-flex flex-row justify-content-between align-items-center">
                            <div className="message">
                                {this.props.resultError}
                            </div>
                            <i className="fas fa-times-circle text-danger"/>
                        </div>
                        :
                        <Suspense fallback={<Water/>}>

                        {
                            ResultRouter
                        }
                        </Suspense>
                }

                {
                    this.props.result && this.props.result.callback?
                        <ResultCallBackForm result={this.props.result}/>
                        :
                        null
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return state.app;
}

export default connect(mapStateToProps, null)(withTranslation()(Result));