const Utils = {
    capitalizeFirstChar: function (sentence) {
        return sentence.charAt(0).toUpperCase() + sentence.slice(1);
    },
    showAsNumberFormat: function (number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getBaseUrl: function () {
        let domain = window.location.hostname;
        if (domain.indexOf('www.') > -1) {
            domain = domain.substr(4);
        }
        const protocol = window.location.protocol;
        // apiBaseUrl = protocol + '//api.' + domain + '/v1/';
        if (process.env.NODE_ENV !== 'production') {
            return protocol + '//api.techpart.click/v1/';
        } else {
            return protocol + '//' + domain + '/api/v1/';
        }
    },
    delay: function (millisecond) {
        return new Promise(function (resolve) {
            setTimeout(resolve, millisecond)
        });
    },
    convertToEnglishNumber: function (num) {
        const persianDigits = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g];
        const arabicDigits = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g];

        // Replace Persian digits with English digits
        persianDigits.forEach((digit, index) => {
            num = num.replace(digit, index);
        });

        // Replace Arabic digits with English digits
        arabicDigits.forEach((digit, index) => {
            num = num.replace(digit, index);
        });

        return num;
    },
}

export default Utils;
