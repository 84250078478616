const appType = {
    SET_Base_Data: 'SET_Base_Data',
    SET_CURRENT_STEP: 'SET_CURRENT_STEP',
    SET_PAYMENT_METHOD: 'SET_PAYMENT_METHOD',
    SET_REF_CODE: 'SET_REF_CODE',
    SET_ERROR_MESSAGE: 'SET_ERROR_MESSAGE',
    SET_AUTH_DATA: 'SET_AUTH_DATA',
    SET_RESULT_ERROR: 'SET_RESULT_ERROR',
    SET_RESULT: 'SET_RESULT',
    SET_SERVICE_ID: 'SET_SERVICE_ID',
    SET_MIDDLEWARE: 'SET_MIDDLEWARE',
    SET_PROCESS: 'SET_PROCESS',
    SET_SOURCE_CARD: 'SET_SOURCE_CARD',
    SET_CVV: 'SET_CVV',
    SET_EXP: 'SET_EXP',
    SET_ACCOUNT_NUMBER: 'SET_ACCOUNT_NUMBER',
    SET_PAYMENT_COMPANY: 'SET_PAYMENT_COMPANY',
    SET_BANK: 'SET_BANK',
    SET_VERIFY_SMS_CODE: 'SET_VERIFY_SMS_CODE',
    SET_INTERNET_BANK_KEY: 'SET_INTERNET_BANK_KEY',
    SET_INTERNET_BANK_INIT_STATUS: 'SET_INTERNET_BANK_INIT_STATUS',
    SET_INTERNET_BANK_AMOUNT: 'SET_INTERNET_BANK_AMOUNT',
    SET_INTERNET_BANK_DESCRIPTION: 'SET_INTERNET_BANK_DESCRIPTION',
}

export default appType;
