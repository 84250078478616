import React from "react";

class ResultCallBackForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {}
    }

    componentDidMount() {
        setTimeout(() => {
            this.form.submit();
        }, 4000);
    }

    render() {
        const {result} = this.props;
        return (
            <div className="result-callback-section">
                {
                    result ?
                        <form ref={item => this.form = item} id="callbackForm" method="post" action={result.callback}>
                            <input name="type" type="hidden"    value={result.data.type}/>
                            <input name="status" type="hidden"  value={result.data.status}/>
                            <input name="refCode" type="hidden" value={result.data.refCode} />
                            <input name="amount" type="hidden"  value={result.data.amount}/>
                            <input name="updated" type="hidden" value={result.data.updated}/>
                            <input name="hash" type="hidden"    value={result.data.hash}/>
                        </form>
                        : null
                }
            </div>
        )
    }
}

export default ResultCallBackForm;
