import React from "react";

function AlertMessage({text, ...otherProps}) {
    return (
        <React.Fragment>
            {
                text ?
                    <div className="alert alert-danger" role="alert">
                        {text}
                    </div>
                    :
                    null
            }

        </React.Fragment>

    );
}

export default AlertMessage;