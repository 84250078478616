import axios from 'axios';
import LocalStorage from '../services/LocalStorage'

let apiBaseUrl = '';
let protocol = 'https:';
if (process.env.NODE_ENV !== 'production') {
    apiBaseUrl = protocol + '//api.yaspay.click/v1/';
} else {
    let domain = window.location.hostname;
    if (domain.indexOf('www.') > -1) {
        domain = domain.substr(4);
    }
    protocol = window.location.protocol;
    // apiBaseUrl = protocol + '//api.' + domain + '/v1/';
    apiBaseUrl = protocol + '//' + domain + '/api/v1/';
}

let Api = axios.create({
    baseURL: apiBaseUrl,
    responseType: 'json',
    timeout: 1000 * 150,
});

Api.CancelToken = axios.CancelToken;
Api.isCancel = axios.isCancel;

Api.interceptors.request.use(function (config) {
    config.headers.Authorization = LocalStorage.getItem('accessToken') ? 'Bearer ' + Storage.getItem('accessToken') : null;
    config.headers['X-Lang'] = 'test';
    return config;
}, function (error) {
    return Promise.reject(error);
});


Api.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    return Promise.reject(error);
});

export default Api;
