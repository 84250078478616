import React from "react";
import Helper from "../../services/Helper";
import {withTranslation} from "react-i18next";
import Water from "../../commons/water/Water";

class Intro extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            paymentMethods: {},
        };
    }

    componentDidMount() {
        const paymentMethods = Helper.objectToArray(this.props.paymentMethods).sort(Helper.orderSortingFunc);

        this.setState({
            isLoaded: true,
            paymentMethods: paymentMethods,
        });
    }

    title(title, tr, lang) {
        return tr != null && lang in tr ? tr[lang] : title;
    }

    render() {
        const {isLoaded, paymentMethods} = this.state;
        const {t, paymentMethod, choosePaymentMethod} = this.props;

        if (!isLoaded) return <Water/>;

        if (paymentMethods != null) {
            const rows = paymentMethods.map((payMethod, index) =>
                <li key={index} onClick={() => {choosePaymentMethod(payMethod.id, payMethod.company)}}>
                    <div className="holderImage">
                        <img src={payMethod.logo} alt={payMethod.name}/>
                    </div>
                    <div className="holderCaption">
                        <span className="caption">{this.title(payMethod.title, payMethod.tr, t('lang'))}</span>
                    </div>
                    {payMethod.buyCardUrl != null && (
                        <a className="buyURL" href={payMethod.buyCardUrl} target="_blank"  rel="noreferrer">
                            {t('buyCard', {paymentMethod: this.title(payMethod.title, payMethod.tr, t('lang'))})}
                        </a>
                    )}
                </li>
            );
            return (
                <div className="paymentMethodContent mt-5">
                    <p>{t('choosePaymentMethod')}:</p>
                    <ul className="paymentMethods2ndTpl d-flex justify-content-center">
                        {rows}
                    </ul>
                </div>
            );
        } else {
            return (
                <div className="paymentMethodContent mt-5">
                    <p>{t('choosePaymentMethod')}:</p>
                    <ul className="paymentMethods">
                        <li onClick={() => choosePaymentMethod(2)}>
                            <img src="../storage/image/payment-method/c2c.png" alt="کارت به کارت"/>
                            <div className="text">
                                <span className="title">{t('cardToCard')} {paymentMethod === 2 &&
                                    <i className="fa fa-check-circle"/>}</span>
                                <span className="description">در این روش می توانید وجه را به صورت کارت به کارت واریز نمایید.</span>
                            </div>
                        </li>
                        <li onClick={() => choosePaymentMethod(1)}>
                            <img src="../storage/image/payment-method/c2c.png" alt="درگاه بانکی"/>
                            <div className="text">
                                <span className="title">{t('bankGateWay')} {paymentMethod === 1 &&
                                    <i className="fa fa-check-circle"/>}</span>
                                <span className="description">در این روش میتوانید از طریق درگاه بانکی وجه را واریز نمایید.</span>
                            </div>
                        </li>
                    </ul>
                </div>
            );
        }
    }
}

export default withTranslation()(Intro);
