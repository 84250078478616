import appType from './app.type';

export const setCurrentStep = (step) => ({
    type: appType.SET_CURRENT_STEP,
    payload: step
});

export const setPaymentMethod = (method) => ({
    type: appType.SET_PAYMENT_METHOD,
    payload: method
});
export const setPaymentCompany = (paymentCompanyObj) => ({
    type: appType.SET_PAYMENT_COMPANY,
    payload: paymentCompanyObj
});

export const setProcess = (is) => ({
    type: appType.SET_PROCESS,
    payload: is
});

export const setRefCode = (refCode) => ({
    type: appType.SET_REF_CODE,
    payload: refCode
});
export const setVerifySmsCode = (smsCode) => ({
    type: appType.SET_VERIFY_SMS_CODE,
    payload: smsCode
});

export const setBaseData = (data) => ({
    type: appType.SET_Base_Data,
    payload: data
});

export const setErrorMessage = (errorMessage) => ({
    type: appType.SET_ERROR_MESSAGE,
    payload: errorMessage
});

export const setAuthData = (data) => ({
    type: appType.SET_AUTH_DATA,
    payload: data
});

export const setResult = (data) => ({
    type: appType.SET_RESULT,
    payload: data
});

export const setResultError = (errorMessage) => ({
    type: appType.SET_RESULT_ERROR,
    payload: errorMessage
});

export const setServiceId = (serviceId) => ({
    type: appType.SET_SERVICE_ID,
    payload: serviceId
});

export const setMiddleware = (dataObject) => ({
    type: appType.SET_MIDDLEWARE,
    payload: dataObject
});

export const setSourceCard = (sourceCard) => ({
    type: appType.SET_SOURCE_CARD,
    payload: sourceCard
});
export const setAccountNumber = (accountNumber) => ({
    type: appType.SET_ACCOUNT_NUMBER,
    payload: accountNumber
});

export const setExp = (exp) => ({
    type: appType.SET_EXP,
    payload: exp
});

export const setCvv = (cvv) => ({
    type: appType.SET_CVV,
    payload: cvv
});

export const setBank = (bank) => ({
    type: appType.SET_BANK,
    payload: bank
});

export const setInternetBankKey = (bankKey) => ({
    type: appType.SET_INTERNET_BANK_KEY,
    payload: bankKey
});

export const setInternetBankInitStatus = (status) => ({
    type: appType.SET_INTERNET_BANK_INIT_STATUS,
    payload: status
});

export const setInternetBankAmount = (amount) => ({
    type: appType.SET_INTERNET_BANK_AMOUNT,
    payload: amount
});

export const setInternetBankDescription = (description) => ({
    type: appType.SET_INTERNET_BANK_DESCRIPTION,
    payload: description
});

