const Helper = (function () {
    return {
        objectToArray: function objectToArray (obj, addKeyNameAsProperty = null, calculator = null) {
            var arr = [];
            for (var key in obj) {
                if (obj.hasOwnProperty(key)) {
                    if (addKeyNameAsProperty) {
                        obj[key][addKeyNameAsProperty] = key;
                    }
                    calculator && calculator(obj[key]);
                    arr.push(obj[key]);
                }
            }
            return arr;
        },
        orderSortingFunc: function orderSortingFunc (a, b) {
            return a.order - b.order;
        },
    };
})();

export default Helper;