import React, {Suspense} from "react";
import LogoImage from '../src/assets/image/logo.png';
import TelegramImage from '../src/assets/image/telegram.png';
import CountDownTimer from "./commons/count-down/CountDown";
import Api from "./services/Api";
import {connect} from "react-redux";
import {
    setBaseData,
    setCurrentStep,
    setErrorMessage,
    setMiddleware,
    setPaymentCompany,
    setRefCode,
    setResult,
    setResultError
} from "./redux/app/app.actions";
import {withTranslation} from 'react-i18next';
import AlertMessage from "./commons/alert/AlertMessage";
import Result from "./components/Result/Result";
import Intro from "./components/Intro/Index";
import Water from "./commons/water/Water";
import '@fortawesome/fontawesome-free/css/all.min.css';
import './assets/css/scss/main.scss';
import MD5 from "crypto-js/md5";

const lngs = {
    en: {nativeName: 'English'},
    fa: {nativeName: 'فارسی'},
    tr: {nativeName: 'Türkçe'},
    az: {nativeName: 'Azərbaycan'},
    ru: {nativeName: 'Русский'},
    am: {nativeName: 'አማርኛ'},
    bs: {nativeName: 'bosanski'},
    bp: {nativeName: 'Portugues do Brasil'},
    bg: {nativeName: 'български'},
    my: {nativeName: 'မြန်မာ'},
    hr: {nativeName: 'Cruatu'},
    cs: {nativeName: 'čeština'},
    da: {nativeName: 'dansk'},
    nl: {nativeName: 'Nederlands'},
    et: {nativeName: 'eesti keel'},
    tl: {nativeName: 'Filipino'},
    fi: {nativeName: 'Suomalainen'},
    fr: {nativeName: 'Français'},
    de: {nativeName: 'Deutsch'},
    ka: {nativeName: 'ქართული'},
    el: {nativeName: 'Ελληνικά'},
    he: {nativeName: 'עִברִית'},
    id: {nativeName: 'bahasa Indonesia'},
    it: {nativeName: 'Italiana'},
    ja: {nativeName: '日本'},
    ko: {nativeName: '한국인'},
    ku: {nativeName: 'کوردی'},
    la: {nativeName: 'Latinus'},
    lv: {nativeName: 'latviski'},
    lt: {nativeName: 'lietuvių'},
    ms: {nativeName: 'Melayu'},
    nb: {nativeName: 'norsk'},
    pt: {nativeName: 'Português'},
    ro: {nativeName: 'Română'},
    sr: {nativeName: 'Српски'},
    ch: {nativeName: '简体中文'},
    sk: {nativeName: 'slovenský'},
    sl: {nativeName: 'slovenščina'},
    sp: {nativeName: 'Español'},
    sw: {nativeName: 'kiswahili'},
    sv: {nativeName: 'svenska'},
    th: {nativeName: 'ไทย'},
    uz: {nativeName: 'o\'zbek'},
    vi: {nativeName: 'Tiếng Việt'},
};

class App extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            lang: 'fa',
            isLoaded: false,
            paymentMethod: 0,
            paymentMethods: [],
            company: null,
            paymentMethodId: 0,
            international: false,
        };
        this.componentsMapCache = new Map();
    }

    async componentDidMount() {
        const {t, i18n} = this.props;

        let uri = window.location.pathname.substring(1);
        let urlParams = uri.split('/');

        if (urlParams[0] === 'result' && isNaN(urlParams[1])) {
            let company = urlParams[1];
            this.props.setResult({
                paymentMethod: company,
            });
            //this.props.setResultError('آدرس وارد شده اشتباه است.');
            this.props.setCurrentStep(100);
            this.setState({
                isLoaded: true,
            });
            return false;
        }

        const refCode = urlParams[0];
        if (refCode === null || refCode === '') {
            this.props.setResultError(t('wrongPath'));
            this.props.setCurrentStep(100);
            this.setState({
                isLoaded: true,
            });

            return false;
        }

        this.props.setRefCode(refCode);

        const urlPaymentMethodId = urlParams[1];
        if (urlPaymentMethodId !== undefined) {
            await Api.get('payment2/get-payment?id=' + urlPaymentMethodId).then(res => {
                let data = res.data;
                this.props.setPaymentCompany({
                    'paymentMethodId': data.id,
                    'company': data.company,
                    'method': data.name,
                })
            }).catch(err => {
                this.setState({
                    isLoaded: true,
                });
                if (err.response) {
                    this.props.setResultError(err.response.data.message);
                    this.props.setCurrentStep(100);
                }
            });
        }


        await Api.post('payment2/get-data', {
            refCode: refCode,
        }).then(res => {
            if (res.data.status === true) {
                this.props.setBaseData(res.data);
                const paymentMethodsCount = res.data.paymentMethods.length;
                const GatewayMethod = res.data.paymentMethods.filter(item => item.id === 1).length > 0;
                const C2CMethod = res.data.paymentMethods.filter(item => item.id === 2).length > 0;
                const IRRMethodsCount = GatewayMethod + C2CMethod;

                if (paymentMethodsCount === 0) {
                    this.props.setResultError(t('noAvailablePaymentMethod'));
                    this.props.setCurrentStep(100);
                }

                const componentMap = {
                    'gateway': 2, 'ctc': 1,
                };

                this.setState({
                    paymentMethod: IRRMethodsCount > 0 ? IRRMethodsCount > 1 ? 0 : C2CMethod ? componentMap[1] : (GatewayMethod ? componentMap[2] : 0) : 0,
                    paymentMethods: res.data.paymentMethods,
                    lang: res.data.lang,
                }, () => {
                    /* Change language in construct */
                    i18n.changeLanguage(res.data.lang, () => {
                        document.documentElement.dir = i18n.dir();
                        document.documentElement.lang = res.data.lang;
                    });
                });
            } else {
                this.props.setResultError(res.data.message);
                this.props.setCurrentStep(100);
            }
            this.setState({
                isLoaded: true,
            });
        }).catch((error) => {
            this.setState({
                isLoaded: true,
            });
            if (error.response) {
                this.props.setResultError(error.response.data.message);
                this.props.setCurrentStep(100);
            }
        });
    }

    changeLanguage = (event) => {
        const {i18n} = this.props;
        const value = event.target.value;

        i18n.changeLanguage(value, () => {
            this.setState({
                lang: value,
            }, () => {
                document.documentElement.dir = i18n.dir();
                document.documentElement.lang = value;
            });
        });
    }

    handleZeroCounter = () => {
        const {t} = this.props;

        this.props.setErrorMessage(null);
        if (this.props.currentStep !== 100) {
            this.props.setMiddleware(null);
            this.props.setResultError(t('timeIsOver'));
        }
        this.props.setCurrentStep(100);

    }

    choosePaymentMethod = (paymentMethodId, company) => {
        this.props.setPaymentCompany({
            paymentMethodId: paymentMethodId,
            company: company,
        })
    }

    getCachedLazy = (currentStep, paymentMethods, paymentMethod, paymentMethodId, company) => {
        paymentMethod = paymentMethod !== undefined ? paymentMethod : 0;
        company = company != null ? company : "internal"; // default value is just for convert hole key to string because cache not work with integer
        let key = currentStep + paymentMethod + paymentMethodId + company;

        let cacheKey = MD5(key).toString();

        if (this.componentsMapCache.has(cacheKey)) return this.componentsMapCache.get(cacheKey);

        let Router;
        if (currentStep === 100) {
            Router = <Result/>;
        } else {
            if (paymentMethodId === 0) {
                Router = <Intro paymentMethod={paymentMethod} paymentMethods={paymentMethods}
                                choosePaymentMethod={this.choosePaymentMethod}/>;
            }/* else if (paymentMethodId === 1) {// Gateway
                    Router = <Gateway company={company} paymentMethodId={paymentMethodId} />;
                } else if (paymentMethodId === 2) {// C2C
                    Router = <C2C company={company} paymentMethodId={paymentMethodId} />;
                }*/
            else {
                const component = React.lazy(() => import('./components/' + company + '/Index'));
                Router = React.createElement(component, {company: company, paymentMethodId: paymentMethodId});
            }
        }


        this.componentsMapCache.set(cacheKey, Router);

        return Router;
    }


    render() {
        if (['fa'].includes(this.state.lang)) {
            require('bootstrap/dist/css/bootstrap.rtl.css');
        } else {
            require('bootstrap/dist/css/bootstrap.min.css');
        }


        const {isLoaded, paymentMethod, paymentMethods} = this.state;
        const {paymentMethodId, company} = this.props.paymentCompanyObj;
        const {t} = this.props;

        if (isLoaded === false) {
            return false;
        }

        const props = this.props;
        const baseData = props.baseData;

        let MyLazyComponent = null;
        if (isLoaded) {
            MyLazyComponent = this.getCachedLazy(this.props.currentStep, paymentMethods, paymentMethod, paymentMethodId, company);
        }

        return (<div className="wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="main-box row">
                            <div className="side-box col-md-4 col-sm-12 d-flex flex-column">
                                <div className="topArea">
                                    <div className="logo">
                                        <img src={LogoImage} alt="logo"/>
                                    </div>
                                    <a href="https://t.me/BankApiSupport" className="support" target="_blank"  rel="noreferrer">
                                        <span>{t('support')}</span>
                                        <img src={TelegramImage} alt="support"/>
                                    </a>
                                    <div className="container-fluid">
                                        <div className="row justify-content-sm-around">
                                            <div
                                                className="section-box timer col-sm-6 col-md-12 d-flex flex-row justify-content-between align-items-sm-center">
                                                <span className="title">{t('remainTime')}:</span>
                                                {baseData ? <CountDownTimer seconds={baseData.expire}
                                                                            handleZeroCounter={this.handleZeroCounter}/> : null}
                                            </div>
                                            <div className="section-box details col-sm-6 col-md-12">
                                                <div className="website">
                                                    <span className="title">{t('webSite')}:</span>
                                                    {baseData ? <span className="text">{baseData.title}</span> : null}
                                                </div>
                                                <div className="user">
                                                    <span className="title">{t('player')}:</span>
                                                    {baseData ?
                                                        <span className="text">{baseData.playerId}</span> : null}
                                                </div>
                                                <div className="ref-code">
                                                    <span className="title">{t('paymentNumber')}:</span>
                                                    {this.props.refCode ?
                                                        <span className="text">{this.props.refCode}</span> : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="bottomArea">
                                    <select className="form-control lang" onChange={this.changeLanguage}
                                            defaultValue={this.state.lang}>
                                        {Object.keys(lngs).map((lng, index) => (
                                            <option key={index} value={lng}>{lngs[lng].nativeName}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="content-box col-md-8 col-sm-12">

                                <AlertMessage text={props.errorMessage}/>

                                <div className="content">
                                    <Suspense fallback={<Water/>}>
                                        {!isLoaded ? <Water/> : MyLazyComponent}
                                    </Suspense>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }
}

const mapStateToProps = state => {
    return state.app;
}

const mapDispatchToProps = dispatch => ({
    setRefCode: refCode => dispatch(setRefCode(refCode)),
    setBaseData: data => dispatch(setBaseData(data)),
    setPaymentCompany: obj => dispatch(setPaymentCompany(obj)),
    setCurrentStep: step => dispatch(setCurrentStep(step)),
    setErrorMessage: errorMessage => dispatch(setErrorMessage(errorMessage)),
    setResult: data => dispatch(setResult(data)),
    setResultError: errorMessage => dispatch(setResultError(errorMessage)),
    setMiddleware: obj => dispatch(setMiddleware(obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(App));
