import appType from './app.type';

const INITIAL_STATE = {
    baseData: null,
    currentStep: 1,
    paymentMethod: 'gateway',
    paymentCompanyObj: {
        paymentMethodId: 0,
        company: null,
    },
    refCode: null,
    errorMessage: null,
    mobile: null,
    amount: null,
    bank: null,
    resultError: null,
    result: null,
    serviceId: null,
    middleware: null,
    process: false,
    sourceCard: null,
    exp: null,
    cvv: null,
    accountNumber: null,
    verifySmsCode: null,
    internetBank: {
        bank: null,
        initStatus: false,
        amount: 0,
        description: null,
    }
};

const appReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case appType.SET_CURRENT_STEP:
            return {
                ...state,
                currentStep: action.payload,
            }
        case appType.SET_PROCESS:
            return {
                ...state,
                process: action.payload,
            }
        case appType.SET_PAYMENT_METHOD:
            return {
                ...state,
                paymentCompany: action.payload,
            }
        case appType.SET_PAYMENT_COMPANY:
            return {
                ...state,
                paymentCompanyObj: action.payload,
            }
        case appType.SET_REF_CODE:
            return {
                ...state,
                refCode: action.payload,
            }
        case appType.SET_VERIFY_SMS_CODE:
            return {
                ...state,
                verifySmsCode: action.payload,
            }
        case appType.SET_Base_Data:
            return {
                ...state,
                baseData: action.payload,
            }
        case appType.SET_ERROR_MESSAGE:
            return {
                ...state,
                errorMessage: action.payload,
            }
        case appType.SET_AUTH_DATA:
            return {
                ...state,
                bank: action.payload.bank,
                mobile: action.payload.mobile,
                amount: action.payload.amount
            }
        case appType.SET_RESULT:
            return {
                ...state,
                result: action.payload,
            }
        case appType.SET_RESULT_ERROR:
            return {
                ...state,
                resultError: action.payload,
            }
        case appType.SET_SERVICE_ID:
            return {
                ...state,
                serviceId: action.payload,
            }
        case appType.SET_MIDDLEWARE:
            return {
                ...state,
                middleware: action.payload
            }
        case appType.SET_SOURCE_CARD:
            return {
                ...state,
                sourceCard: action.payload
            }
        case appType.SET_EXP:
            return {
                ...state,
                exp: action.payload
            }
        case appType.SET_CVV:
            return {
                ...state,
                cvv: action.payload
            }
        case appType.SET_ACCOUNT_NUMBER:
            return {
                ...state,
                accountNumber: action.payload
            }
        case appType.SET_BANK:
            return {
                ...state,
                bank: action.payload
            }

        case appType.SET_INTERNET_BANK_KEY:
            return {
                ...state,
                internetBank: {
                    ...state.internetBank,
                    bank: action.payload
                },
            }
        case appType.SET_INTERNET_BANK_INIT_STATUS:
            return {
                ...state,
                internetBank: {
                    ...state.internetBank,
                    initStatus: action.payload
                },
            }
        case appType.SET_INTERNET_BANK_AMOUNT:
            return {
                ...state,
                internetBank: {
                    ...state.internetBank,
                    amount: action.payload
                },
            }
        case appType.SET_INTERNET_BANK_DESCRIPTION:
            return {
                ...state,
                internetBank: {
                    ...state.internetBank,
                    description: action.payload
                },
            }
        default:
            return state;
    }
};

export default appReducer;
